import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="newsletter-subscription"
export default class extends Controller {
  static targets = ["email", "reset", "alert", "alertinvalid"];

  sendMail(event) {
    event.preventDefault();

    let email = this.emailTarget.value;

    // Reset alerts
    this.alertTarget.style.display = "none";
    this.alertinvalidTarget.style.display = "none";

    // Simple email format validation
    const emailRegEx = /\S+@\S+\.\S+/;
    if (!emailRegEx.test(email)) {
      this.alertinvalidTarget.style.display = "block";
      document.getElementById("alert-text-danger").innerHTML = `<i class="fa-solid fa-triangle-exclamation"></i> L'adresse email <strong>${email}</strong> ne semble pas valide !`;
      return;
    }

    fetch('/register_newsletter', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({ email: email })
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      this.sendConfirmation(event);
      this.resetTarget.click();
    }).catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
    });
  }


  sendConfirmation(event) {
    event.preventDefault();

    let email = this.emailTarget.value;

    fetch('/register_newsletter_litisprovence', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content
      },
      body: JSON.stringify({ email: email })
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Modify the text of the alert to include the user's email
      this.alertTarget.style.display = "block";
      document.getElementById("alert-text-success").innerHTML = `L'email <strong>${email}</strong> a bien été ajouté à la liste de diffusion de notre newsletter ! Consultez votre boite mail.`;

      // Hide the alert after 5 seconds
      setTimeout(() => {
        this.alertTarget.style.opacity = "0";
        // After the opacity transition ends, set display to none
        this.alertTarget.addEventListener('transitionend', () => {
          this.alertTarget.style.display = "none";
          this.alertTarget.style.opacity = "1";
        }, { once: true }); // The listener will remove itself after being invoked
      }, 9000);

    }).catch(error => {
      console.error('There has been a problem with your fetch operation:', error);
    });

  }
}
